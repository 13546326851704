<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <b-link class="brand-logo">
                <img :src="imgLogo" alt="" height="80">
            </b-link>

            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img fluid :src="imgUrl" alt="Login V2"/>
                </div>
            </b-col>

            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
                        Benvingut
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Inicia sessió en el seu compte
                    </b-card-text>

                    <validation-observer ref="loginForm" #default="{invalid}">
                        <b-form class="auth-login-form mt-2" @submit.prevent="login">
                            <b-form-group label="Email" label-for="login-email">
                                <validation-provider
                                    name="Email"
                                    vid="email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="login-email"
                                        v-model="userEmail"
                                        name="login-email"
                                        placeholder="Email"
                                    />
                                </validation-provider>
                            </b-form-group>

                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label>Contrasenya</label>
                                </div>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Password"
                                    vid="password"
                                    rules="required"
                                >
                                    <b-input-group class="input-group-merge"
                                                   :class="errors.length > 0 ? 'is-invalid':null"
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="password"
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder="Contrasenya"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="togglePasswordVisibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                </validation-provider>
                            </b-form-group>

                            <b-button
                                type="submit"
                                variant="primary"
                                block
                                :disabled="invalid || loading"
                            >
                                <b-spinner v-if="loading" label="Carregant..."/>
                                <span v-else>Iniciar sessió</span>
                            </b-button>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
    BSpinner, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText,
    BCardTitle, BImg, BForm, BButton, VBTooltip
} from 'bootstrap-vue'
import {required, email} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import Vue from "vue"

export default {
    directives: {
        'b-tooltip': VBTooltip,
    },
    components: {
        BSpinner,
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            loading: false,
            status: '',
            password: '',
            userEmail: '',
            sideImg: require('@/assets/images/pages/login-v2.png'),

            // validation rules
            required,
            email,
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        },
        imgLogo() {
            return require('@/assets/images/logo/logo_gag.png')
        },
    },
    methods: {
        login() {
            this.loading = true
            this.$refs.loginForm.validate().then(success => {
                if (success) {
                    this.$store.dispatch('auth/login', {
                        email: this.userEmail,
                        password: this.password,
                    }).then(response => {
                        const userData = response.data.user

                        this.loading = false
                        this.$router.replace('/')
                            .then(() => {
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: `Benvingut ${userData.name || userData.username}`,
                                        icon: 'CoffeeIcon',
                                        variant: 'success',
                                        text: `Ha iniciat sessió correctament. Ara pots començar a explorar!`,
                                    },
                                })
                            })
                    }).catch(error => {
                        Vue.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: error.message,
                                icon: 'CoffeeIcon',
                                variant: 'danger',
                            },
                        })
                        this.loading = false
                        this.$refs.loginForm.setErrors(error.message)
                    })
                }
            })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
